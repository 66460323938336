/* @import url('http://fonts.cdnfonts.com/css/tt-commons'); */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500&display=swap');

*{
    /* font-family: 'TT Commons', sans-serif !important; */
    font-family: 'Cinzel', serif;
    letter-spacing: 2px;
    line-height: 250%;
    scroll-behavior: smooth; 
}

::selection{
    background-color: rgb(255,193,7);
    color: #000;    
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

/**** CUSTOM HAMBURGER MENU ****/
/* Overriding Bootstrap class */

.navbar-dark .navbar-toggler-icon {
    /* background-image: url("https://cdn-icons.flaticon.com/png/512/3384/premium/3384313.png?token=exp=1650873868~hmac=f3c73618d75fc51065e2833f9184fb93") !important; */
    background-image: url("https://cdn-icons-png.flaticon.com/512/463/463292.png");
}

.navbar-dark .navbar-toggler {
    border: none;
}

.navbar-toggler-icon{
    display: inline-block;
    vertical-align: middle;
    height: 1.5em;
    width: 1.5em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border: none !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}


/******** ANIMATIONS *********/

/* FADE-IN-UP ANIMATION */

@keyframes  fadeInUp {
    from {
        transform: translate3d(0,60px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,60px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

/* FADE-IN-LEFT ANIMATION */

@keyframes  fadeInLeft {
    from {
        transform: translate3d(-60px,0,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInLeft {
    from {
        transform: translate3d(-60px,0,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animatedFadeInLeft {
    opacity: 0
}

.fadeInLeft {
    opacity: 0;
    animation-name: fadeInLeft;
    -webkit-animation-name: fadeInLeft;
}

/* FADE-IN-RIGHT ANIMATION */

@keyframes  fadeInRight {
    from {
        transform: translate3d(60px,0,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInRight {
    from {
        transform: translate3d(60px,0,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animatedFadeInRight {
    opacity: 0
}

.fadeInRight {
    opacity: 0;
    animation-name: fadeInRight;
    -webkit-animation-name: fadeInRight;
}

/* FADE ANIMATION DELAY*/

.animation-delay{
    animation-delay: 0.30s;
}
.animation-delay2{
    animation-delay: 0.40s;
}
.animation-delay3{
    animation-delay: 0.50s;
}
.animation-delay4{
    animation-delay: 0.60s;
}
.animation-delay5{
    animation-delay: 0.70s;
}
.animation-delay6{
    animation-delay: 0.80s;
}

/******* TRANSFORM *********/
.transform{
    transition: transform .3s;
}
.transform:hover{
    -ms-transform: scale(1.0); /*IE*/
    transform: scale(1.1);
}


/******BACKGROUND ANIMATION*******/

.bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, rgb(197, 190, 168) 50%, rgb(212, 211, 211) 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:7s;
  }
  
  .bg3 {
    animation-duration:8s;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }